import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  SimpleGrid,
  Group,
  rem,
  useMantineTheme,
  Text,
  Card,
  Tooltip,
  Button,
  ColorPicker,
  Flex,
  Select,
  ScrollArea,
  Stack,
  HoverCard,
  ActionIcon,
  CopyButton,
} from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { ReactComponent as Template } from "../../../images/durian_artwork_template.svg";
import { ReactComponent as TemplateEmpty } from "../../../images/durian_artwork_template_empty.svg";
import { ReactComponent as TemplateNamed } from "../../../images/durian_artwork_template_names.svg";
import { IconCircleX, IconCreditCard, IconTexture, IconCopy, IconCheck } from "@tabler/icons";

export default function CardArtworkVerification({ companyData }) {
  const theme = useMantineTheme();

  const openRef = useRef(null);
  // const openPDFRef = useRef(null);

  useEffect(() => {
    document.title = "Card Artwork Verification - Cell Software Portal";
  }, []);

  const [files, setFiles] = useState([]);
  const [bgColor, setBgColor] = useState("#000000");
  const [templayeStyle, setTemplateStyle] = useState("default");
  let dropzoneAccepts = ["image/png"];
  // let pdfLimit = 1;
  const [isPDFLoading] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      // go through all files
      files.forEach((f, index) => {
        checkImageSize(f, (valid, width, height) => {
          setFileDim((prevValue) => [
            ...prevValue,
            { index: index, valid: valid, size: `${width ?? 0} x ${height ?? 0}` },
          ]);
        });
      });
    } else {
      setFileDim([]);
    }
  }, [files]);

  const [fileDim, setFileDim] = useState([]);

  const previews = files.map((file, index) => {
    return (
      <Card key={index} shadow="xs" padding="lg" radius="0px">
        <Card.Section pb="xs">
          {templayeStyle === "named" ? (
            <TemplateNamed
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          ) : templayeStyle === "none" ? (
            <TemplateEmpty
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          ) : (
            <Template
              style={{
                backgroundImage: `url(${URL.createObjectURL(file)})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundColor: bgColor,
              }}
            />
          )}
        </Card.Section>
        <Stack spacing={8}>
          <Flex justify="space-between">
            <Text lineClamp={1}>{file.name}</Text>
            <CopyButton value={file.name} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy Title"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Flex>

          <Group grow align="top">
            <Stack spacing={0}>
              <Text fz="xs" color="dimmed">
                Dimensions
              </Text>
              {fileDim.map((d) => {
                if (d.index === index) {
                  return (
                    <Tooltip.Floating
                      key={index}
                      label="The dimensions of the card MUST be 1536px x 969px."
                      color="red"
                      disabled={d?.valid ?? false}
                    >
                      <Text fw="bold" color={d?.valid ? "green" : "red"}>
                        {d?.size}
                      </Text>
                    </Tooltip.Floating>
                  );
                } else {
                  return null;
                }
              })}
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" color="dimmed">
                Size
              </Text>
              <Tooltip.Floating
                label="The size of the card MUST be 4MB or below."
                color="red"
                disabled={file.size <= 4000000}
              >
                <Text fw="bold" color={file.size <= 4000000 ? "green" : "red"}>
                  {formatBytes(file.size)}
                </Text>
              </Tooltip.Floating>
            </Stack>
          </Group>
        </Stack>
      </Card>
    );
  });

  //   function for getting image size
  function checkImageSize(file, completion) {
    var fr = new FileReader();
    fr.onload = function () {
      // File is loaded
      var img = new Image();
      img.onload = function () {
        // The image is loaded; sizes are available
        if (img.width === 1536 && img.height === 969) {
          completion(true, img.width, img.height);
        } else {
          completion(false, img.width, img.height);
        }
      };
      img.src = fr.result; // Is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(file);
  }

  // Format size
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <SimpleGrid breakpoints={[{ minWidth: "xs", cols: 2 }]}>
      {files.length > 0 ? (
        <ScrollArea h={700} offsetScrollbars type="always">
          <SimpleGrid cols={2} breakpoints={[{ minWidth: "xs", cols: 2 }]}>
            {previews}
          </SimpleGrid>
        </ScrollArea>
      ) : (
        <Dropzone
          accept={dropzoneAccepts}
          loading={isPDFLoading}
          onDrop={(files) => {
            setFileDim([]);
            setFiles(files);
          }}
          openRef={openRef}
        >
          <Group position="center" spacing="xl" style={{ minHeight: rem(600) }}>
            <Dropzone.Accept>
              <iconprogre
                size="3.2rem"
                stroke={2}
                color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconCircleX size="3.2rem" stroke={2} color="red" />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconCreditCard size="3.2rem" stroke={2} />
            </Dropzone.Idle>

            <Stack spacing={0}>
              <Text size="xl" fw="bold" align="center">
                Drop card artworks here or click to Browse...
              </Text>
              <Text size="sm" color="dimmed">
                Drop as many as many PNG's as you like.
              </Text>
            </Stack>
          </Group>
        </Dropzone>
      )}

      <Paper p="lg" shadow="sm" withBorder>
        <Stack spacing="xl">
          <Flex gap="md" align="center">
            <Select
              label="Template Style"
              placeholder="Default"
              value={templayeStyle}
              onChange={setTemplateStyle}
              disabled={files.length === 0}
              w={150}
              data={[
                { label: "Default", value: "default" },
                { label: "Names", value: "named" },
                { label: "None", value: "none" },
              ]}
            />

            <HoverCard width={300} shadow="md" withArrow>
              <HoverCard.Target>
                <ActionIcon variant="filled" aria-label="Background color">
                  <IconTexture style={{ width: "80%", height: "80%" }} stroke={2} />
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <ColorPicker
                  swatchesPerRow={7}
                  format="hex"
                  onChange={setBgColor}
                  value={bgColor}
                  fullWidth
                  swatches={[
                    "#25262b",
                    "#868e96",
                    "#fa5252",
                    "#e64980",
                    "#be4bdb",
                    "#7950f2",
                    "#4c6ef5",
                    "#228be6",
                    "#15aabf",
                    "#12b886",
                    "#40c057",
                    "#82c91e",
                    "#fab005",
                    "#fd7e14",
                  ]}
                />
              </HoverCard.Dropdown>
            </HoverCard>

            {files.length > 0 && (
              <Button mt={16} disabled={isPDFLoading} onClick={() => openRef.current()}>
                Browse More Files...
              </Button>
            )}
          </Flex>

          <Text>
            Use this tool to determine whether an artwork passes or fails against Apple's Guidelines.{" "}
            <b>This tool only supports PNG.</b>
            <br />
            Each card art must adhere to the following requirements:
            <ul>
              <li>be either vector PDF or a PNG file</li>
              <li>size no larger than 4MB</li>
              <li>have a resolution of 1536 × 969</li>
              <li>have squared (not rounded) corners</li>
              <li>
                exclude elements that are relevant only for physical cards (which include the card number, embossed
                characters, hologram, chip contacts)
              </li>
              <li>
                the Bank, Co-brand & Network logos to be <u>inside</u> the red areas
              </li>
              <li>
                must be in landscape orientation; if the physical card has a portrait orientation, it must be modified
                for presentation in landscape orientation
              </li>
              <li>contactless indicator may be added (to help indicate where Apple Pay can be used)</li>
            </ul>
          </Text>
        </Stack>
      </Paper>
    </SimpleGrid>
  );
}
