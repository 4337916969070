import { useState } from "react";
import { createStyles, Navbar, getStylesRef, rem, NavLink, Text } from "@mantine/core";
import {
  IconLogout,
  IconSettingsAutomation,
  IconDashboard,
  IconMessageCircle,
  IconBriefcase,
  IconUsers,
  IconPencil,
  IconSquarePlus,
  IconCreditCard,
  IconWifi,
  IconListCheck,
  IconLayoutBoard,
  IconCalendarDue,
  IconSourceCode,
} from "@tabler/icons";
import { UserButton } from "./UserButton";
import { useLocation } from "react-router-dom";
import { auth } from "../firebase-config";
import { signOut } from "@firebase/auth";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 600,

    "&:hover": {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[5],
      boxShadow: theme.shadows.md,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.colors.blue[5],
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      boxShadow: theme.shadows.md,
      backgroundColor: theme.colors.blue[5],
      color: theme.white,
      fontWeight: 900,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.white,
      },
    },
  },
}));

export function AppNavBar({ currentUser, userClaims, companyData, closeMenu }) {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const location = useLocation();

  const [active, setActive] = useState(location.pathname);

  const isAutomationEnabled = companyData["azure"]["automation_enabled"] ?? false;
  const isTestrailEnabled = companyData["testrail"]["enabled"];
  const isSMSEnabled = companyData["sms"]["enabled"];
  const isBoardsEnabled = companyData["jira"]["boards_enabled"] ?? false;

  const dataClient = [
    { link: "/automations", label: "Automations", icon: IconSettingsAutomation, visible: isAutomationEnabled },
    { link: "/jira", label: "Jira Boards", icon: IconLayoutBoard, visible: isBoardsEnabled },
    { link: "/testrail", label: "TestRail", icon: IconListCheck, visible: isTestrailEnabled },
  ];
  const dataFeatures = [
    { link: "/", label: "Dashboard", icon: IconDashboard },
    { link: "/booker", label: "Booker", icon: IconCalendarDue, visible: true },
    { link: "/automations", label: "Automations", icon: IconSettingsAutomation, visible: isAutomationEnabled },
    { link: "/jira", label: "Jira Boards", icon: IconLayoutBoard, visible: isBoardsEnabled },
    { link: "/testrail", label: "TestRail", icon: IconListCheck, visible: isTestrailEnabled },
    { link: "/sms-messages", label: "SMS Messaging", icon: IconMessageCircle, visible: isSMSEnabled },
  ];
  const dataDurian = [
    { link: "/verification/card-artwork", label: "Card Artwork Verification", icon: IconCreditCard, visible: true },
    { link: "/verification/html", label: "Html Verification", icon: IconSourceCode, visible: true },
    { link: "https://embryo.cellsoftware.co.uk/", label: "Embryo", icon: IconWifi, visible: true, target: "_blank" },
  ];
  const dataCompany = [
    { link: "/clients", label: "Clients", icon: IconBriefcase },
    { link: "/employees", label: "Employees", icon: IconUsers },
    {
      link: "/company/edit",
      label: "Edit Company",
      icon: IconPencil,
      visible: userClaims["access"] === "admin" || userClaims["access"] === "super",
    },
    {
      link: "/company/create",
      label: "Create Business",
      icon: IconSquarePlus,
      visible: userClaims["access"] === "super",
    },
  ];

  const clientLinks = dataClient
    .filter((i) => i.visible ?? true)
    .map((item) => (
      <NavLink
        className={cx(classes.link, { [classes.linkActive]: active === item.link })}
        key={item.label}
        label={item.label}
        icon={<item.icon size="1rem" stroke={1.5} />}
        onClick={(event) => {
          event.preventDefault();
          if (item.link !== active) {
            navigate(item.link);
            setActive(item.link);
            closeMenu(true);
          }
        }}
      />
    ));

  const linksDurian = dataDurian
    .filter((i) => i.visible ?? true)
    .map((item) => (
      <NavLink
        className={cx(classes.link, { [classes.linkActive]: active === item.link })}
        style={item.link === active ? { pointerEvents: "none" } : {}}
        key={item.label}
        label={item.label}
        target={item.target}
        onClick={(event) => {
          event.preventDefault();
          if (item.target) {
            window.open(item.link, item.target);
          } else if (item.link !== active) {
            navigate(item.link);
            setActive(item.link);
            closeMenu(true);
          }
        }}
        icon={<item.icon size="1rem" stroke={1.5} />}
      />
    ));

  const featureLinks = dataFeatures
    .filter((i) => i.visible ?? true)
    .map((item) => (
      <NavLink
        className={cx(classes.link, { [classes.linkActive]: active === item.link })}
        style={item.link === active ? { pointerEvents: "none" } : {}}
        key={item.label}
        target={item.target ?? "_self"}
        label={item.label}
        icon={<item.icon size="1rem" stroke={1.5} />}
        childrenOffset={32}
        onClick={(event) => {
          event.preventDefault();
          if (item.link !== active) {
            navigate(item.link);
            setActive(item.link);
            closeMenu(true);
          }
        }}
      ></NavLink>
    ));

  const companyLinks = dataCompany
    .filter((i) => i.visible ?? true)
    .map((item, index) => (
      <NavLink
        className={cx(classes.link, { [classes.linkActive]: active === index })}
        key={item.label}
        label={item.label}
        icon={<item.icon size="1rem" stroke={1.5} />}
        onClick={(event) => {
          event.preventDefault();
          if (item.link !== active) {
            navigate(item.link);
            setActive(index);
            closeMenu(true);
          }
        }}
      />
    ));

  return (
    <Navbar width={{ sm: 300 }} p="md">
      <Navbar.Section grow>
        {userClaims["access"] === "client" ? (
          clientLinks
        ) : (
          <>
            {featureLinks}
            {(userClaims["durian_member"] ?? false) && (
              <Navbar.Section className={classes.footer}>
                <Text fw="bold" fz="xs" c="grey">
                  Durian
                </Text>{" "}
                {linksDurian}
              </Navbar.Section>
            )}
            <Navbar.Section className={classes.footer}>
              <Text fw="bold" fz="xs" c="grey">
                Company
              </Text>
              {companyLinks}
            </Navbar.Section>
          </>
        )}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UserButton
          image={companyData["avatar"]}
          name={currentUser.displayName}
          email={currentUser.email}
          currentUser={currentUser}
        />

        <a
          href="/"
          className={classes.link}
          onClick={() =>
            signOut(auth).then(() => {
              localStorage.clear();
              navigate("/");
              closeMenu(true);
            })
          }
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}
