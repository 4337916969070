import React, { useEffect, useState, forwardRef } from "react";
import * as apiService from "../../api-service";
import {
  Paper,
  Table,
  Select,
  ActionIcon,
  Flex,
  Tooltip,
  Checkbox,
  Group,
  Text,
  Title,
  CopyButton,
  ScrollArea,
  createStyles,
  rem,
} from "@mantine/core";
import { IconRefresh, IconCheck, IconCopy } from "@tabler/icons";
import moment from "moment";

function SMSMonitor({ userToken, companyId, companyData }) {
  const [isLoading, setIsLoading] = useState(true);

  const [selectedSimId, setSelectedSimId] = useState(companyData.data()["sms"]["sims"][0]?.value ?? "");
  const [smsHistory, setSMSHistory] = useState([]);

  const [selectedFilteredSender, setSelectedFilteredSender] = useState(null);

  const [isFilterDigitEnabled, setIsFilterDigitEnabled] = useState(
    JSON.parse(localStorage.getItem("isFilterDigitEnabled")) ?? true
  );

  const fetchSMSHistory = () => {
    if (userToken && companyId) {
      setIsLoading(true);

      apiService
        .getSMSHistory({
          userIdToken: userToken,
          companyId: companyId,
          simId: selectedSimId,
        })
        .then((data) => {
          setSMSHistory(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.error(e);
        });
    }
  };

  useEffect(() => {
    document.title = "SMS Messaging - Cell Software Portal";
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchSMSHistory(), []);

  function returnDigits(message) {
    return message.match(/\d{8}/) || message.match(/\d{7}/) || message.match(/\d{6}/) || message.match(/\d{4}/) || "";
  }

  const formatDate = (sms_date) =>
    moment(sms_date, "YYYYMMDDHHmmss").calendar({
      sameDay: "[Today], HH:mm:ss",
      lastDay: "[Yesterday], HH:mm:ss",
      lastWeek: "[Last] dddd, HH:mm:ss",
      sameElse: "ddd, MMM D YYYY, HH:mm:ss",
    });

  const rows = smsHistory.map(({ id_message, sender, message, sms_date }) => (
    <tr key={id_message}>
      <td>{formatDate(sms_date)}</td>
      <td>{sender}</td>
      <td>{isFilterDigitEnabled ? <Title order={3}>{returnDigits(message)}</Title> : message}</td>
    </tr>
  ));

  const filteredSenders = smsHistory.map((i) => i.sender) ?? [];

  const smsHistoryFiltered = smsHistory
    .filter((i) => i.sender === selectedFilteredSender)
    .map(({ id_message, sender, message, sms_date }) => (
      <tr key={id_message}>
        <td>{formatDate(sms_date)}</td>
        <td>{sender}</td>
        <td>{isFilterDigitEnabled ? <Title order={3}>{returnDigits(message)}</Title> : message}</td>
      </tr>
    ));

  const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ));

  const useStyles = createStyles((theme) => ({
    header: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      transition: "box-shadow 150ms ease",
      zIndex: 1,
      "&::after": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[2]}`,
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <Paper shadow="md" p="lg" withBorder>
      <Flex pb={16} gap={8} justify="flex-start" align="center" direction="row" wrap="wrap">
        <Select
          label="Available SIMs"
          value={selectedSimId}
          data={companyData.data()["sms"]["sims"] ?? []}
          itemComponent={SelectItem}
          onChange={(i) => setSelectedSimId(i)}
          disabled={isLoading}
        />

        {/* Refresh messages */}

        <Tooltip label="Reload" withArrow>
          <ActionIcon loading={isLoading} mt={24} color="blue" size="lg" radius="md" onClick={fetchSMSHistory}>
            <IconRefresh size={24} />
          </ActionIcon>
        </Tooltip>

        {/* Copy to clipboard */}

        <CopyButton value={selectedSimId} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? "Copied" : "Copy Number"} withArrow position="top">
              <ActionIcon mt={24} size="lg" radius="md" color={copied ? "green" : "blue"} onClick={copy}>
                {copied ? <IconCheck size={24} /> : <IconCopy size={24} />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>

        <Select
          ml={40}
          disabled={[...new Set(filteredSenders)].length === 0 || isLoading}
          label="Filter By Sender"
          value={selectedFilteredSender}
          data={[...new Set(filteredSenders)]}
          onChange={(i) => setSelectedFilteredSender(i)}
          clearable
        />

        {/* OTP Only Checkbox */}

        <Tooltip label="Only see the OTP code in each message." withArrow>
          <Checkbox
            mt={30}
            ml={16}
            disabled={isLoading}
            label="Show OTPs Only"
            checked={isFilterDigitEnabled}
            onChange={(e) => {
              localStorage.setItem("isFilterDigitEnabled", e.currentTarget.checked);
              setIsFilterDigitEnabled(e.currentTarget.checked);
            }}
          />
        </Tooltip>
      </Flex>

      {!isLoading && (
        <ScrollArea style={{ height: 700 }} type="always" offsetScrollbars>
          <Table verticalSpacing="sm">
            <thead className={classes.header}>
              <tr>
                <th>Recieved</th>
                <th>From</th>
                <th>{isFilterDigitEnabled ? "OTP Code" : "Message"}</th>
              </tr>
            </thead>
            <tbody>{selectedFilteredSender === null ? rows : smsHistoryFiltered}</tbody>
          </Table>
        </ScrollArea>
      )}
    </Paper>
  );
}

export default SMSMonitor;
